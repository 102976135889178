import React from 'react';
import './styles.css';

export default function App() {
  //A_L
  return (
  <div>
    <center>
    <div className='Logintext'>Login</div>
    <img src="https://raw.githubusercontent.com/Luqmaan2/logo/Pages/favicon.ico" alt='Logo' className='Logo'  />
    
    </center>
    
    <input className='passwod'placeholder='password' type='password' />
  <button className='Loginbtn' type='submit' >Login</button>
  </div>
  );
}

